@import '../../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
  background: #353535;
  &.headerwithoutLogin {
    background: linear-gradient(90deg, #121213 0%, rgba(18, 18, 19, 0) 52.61%);
  }
  &.viewerLoggedInHeader {
    /* background: linear-gradient(
      169deg,
      rgba(18, 18, 19, 1) 0%,
      rgba(18, 18, 19, 1) 31%,
      rgba(18, 18, 19, 0.42620798319327735) 66%,
      rgba(18, 18, 19, 0.13489145658263302) 78%,
      rgba(18, 18, 19, 0.04805672268907568) 100%
    ); */
    /* background: linear-gradient(
      180deg,
      rgba(18, 18, 19, 1) 0%,
      rgba(18, 18, 19, 0.8428748208267682) 48%,
      rgba(18, 18, 19, 0.7644434482777486) 64%,
      rgba(18, 18, 19, 0.6776087143841911) 81%,
      rgba(18, 18, 19, 0.5907739804906338) 91%,
      rgba(18, 18, 19, 0.4983370057007178) 100%
    ); */
    background: linear-gradient(180deg, #121213 0%, rgba(0, 0, 0, 0.00) 100%);
    position: relative;
  }
  &.fixedHeader {
    background: linear-gradient(90deg, #121213 0%, #121213 52.61%);
  }
}

.loggedInMenu{
  display: flex;
}

.container {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);
  padding: 0 25px;
  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  /* background-color: var(--colorWhite); */

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    height: var(--topbarHeightDesktop);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.searchMenuContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  & > button {
    padding: 0;
    width: auto;
    height: auto;
    min-height: auto;
  }
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.searchMenu {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  /* Layout */
  display: flex;
  width: 66px;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  padding: 15px 24px;
  position: relative;

  @media (--viewportMedium) {
    padding: 21px 24px;
  }
}
.searchMenu {
  padding: 12px 24px;

  @media (--viewportMedium) {
    padding: 18px 24px;
  }
}

.menuIcon,
.searchMenuIcon {
  margin: 9px 0;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 17px;
  right: 17px;

  /* Style: red dot with white border */
  background-color: var(--colorFail);
  border: 2px solid var(--colorWhite);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);

  @media (--viewportMedium) {
    top: 23px;
  }
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--colorGrey700);
}
.rootSearchIcon {
  stroke: var(--colorGrey700);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.mobileHelp {
  color: var(--colorGrey300);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;
  padding: var(--modalPadding);

  @media (--viewportMedium) {
    flex-basis: 576px;
    flex-grow: 1;
    min-height: max(100%, 100vh);
    height: auto;
    padding: 24px;
    background-color: var(--colorWhite);
    border-bottom: none;
  }
}
.modalContainerSearchForm {
  composes: modalContainer;
  padding: 0;
  @media (--viewportMedium) {
    padding: var(--modalPaddingMedium);
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  background-color: var(--marketplaceColorLight);
  border-radius: var(--borderRadiusMedium);
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--colorWhite);

  @media (--viewportMedium) {
    margin: 0;
  }
}


/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuLink {
  color: var(--colorWhite);
  font-family: 'Sofia Pro';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px; /* 211.111% */
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 3px 24px;
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
    background-color: var(--marketplaceColor);
    & .menuItemBorder {
      width: 6px;
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.divider {
  height: 1px;
  background-color: var(--colorWhite);
  max-width: 84%;
  margin: 10px auto;
  width: 100%;
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 0px 24px;

  /* Layout details */
  color: var(--colorWhite);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;

  margin-top: 0;
  &:hover {
    cursor: pointer;
    color: var(--colorWhite);
    text-decoration: none;
    background-color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 0;
  }
}

.notificationContainer{
  position: static;
  color: #121213;
  & .content{
    & .message{
         margin: 0!important;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/* Notifications.module.css */

.notificationContainer {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  max-width: 100vw;
  width: 360px;
}

.notification {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification.success {
  background-color: #4caf50; /* Green for success */
}

.notification.error {
  background-color: #f44336; /* Red for error */
}

.closeButton {
  background: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}
