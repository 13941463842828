@import '../../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);
  max-width: 100vw;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 0 0;

  /* fill */
  /* background-color: var(--colorWhite); */

  /* shadows */
  box-shadow: var(--boxShadowLight);

  /* &.headerwithoutLogin {
    background: linear-gradient(90deg, #121213 0%, rgba(18, 18, 19, 0) 52.61%);
  } */
  &.viewerLoggedInHeader {
    /* background: linear-gradient(
      169deg,
      rgba(18, 18, 19, 1) 0%,
      rgba(18, 18, 19, 1) 31%,
      rgba(18, 18, 19, 0.42620798319327735) 66%,
      rgba(18, 18, 19, 0.13489145658263302) 78%,
      rgba(18, 18, 19, 0.04805672268907568) 100%
    ); */
    /* background: linear-gradient(
      180deg,
      rgba(18, 18, 19, 1) 0%,
      rgba(18, 18, 19, 0.8428748208267682) 48%,
      rgba(18, 18, 19, 0.7644434482777486) 64%,
      rgba(18, 18, 19, 0.6776087143841911) 81%,
      rgba(18, 18, 19, 0.5907739804906338) 91%,
      rgba(18, 18, 19, 0.4983370057007178) 100%
    ); */
    position: relative;
  }

  /* &.fixedHeader {
    background: linear-gradient(90deg, #121213 0%, #121213 52.61%);
  } */
  @media (--viewportLarge) {
    padding: 0 0px 0 0;
  }

  @media (min-width: 1300px) {
    padding: 0 0 0 0;
  }
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
}

.logoLink:hover {
  border-bottom: 4px solid var(--marketplaceColor);
  border-radius: 0;
}

/* Search */
.searchLink {
  min-width: 390px;
  height: 100%;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);
  flex: 1;
}

.loginLink {}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.takeAvailableSpace {
  flex-grow: 1;

  /* At 1024px, the translations might need more space than what searchLink otherwise gets. */
  min-width: 220px;
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  padding-right: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* These is used with Inbox, Sign up, and Log in */
.topbarLink {
  flex-shrink: 0;

  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.signupLink {
  composes: buttonSecondary from global;
  padding: 8px 46px;
  height: auto;
  margin: 15px 0;

  & span {
    margin: 0;
  }
}

.topbarLinkLabel {
  color: #fff;
  text-align: center;
  font-family: 'Sofia Pro';
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 133.333% */
  display: inline-block;
  margin: 24px 0;
  text-decoration: inherit;
  position: relative;
}

.topbarLibraryLinkLabel {
  color: #fff;
  text-align: center;
  font-family: 'Sofia Pro';
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 133.333% */
  display: inline-block;
  margin: 27px 0;
  text-decoration: inherit;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:hover {
    border-bottom: 0px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
  border-radius: 3px;
  border: 1px solid #fff;
  width: 38px;
  height: 38px;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuLink {
  color: var(--colorWhite);
  font-family: 'Sofia Pro';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  /* 211.111% */
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 3px 24px;
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
    background-color: var(--marketplaceColor);

    & .menuItemBorder {
      width: 6px;
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.divider {
  height: 1px;
  background-color: var(--colorWhite);
  max-width: 84%;
  margin: 10px auto;
  width: 100%;
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 0px 24px;

  /* Layout details */
  color: var(--colorWhite);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;

  margin-top: 0;

  &:hover {
    cursor: pointer;
    color: var(--colorWhite);
    text-decoration: none;
    background-color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorWhite);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--Background);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.loggedInMenu {
  display: flex;
  align-items: center;
  gap: 20px;
} 
.menuProfile{
  margin:  0 20px;
}